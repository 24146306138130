<template>
  <div class="indexxx">
    <ModalSuccess
      v-show="showSuccessModal"
      @close="closeModal"
      class="-mt-20 pt-80 pb-60 px-4 md:px-8 md:pt-96 lg:pt-72 lg:px-20 xl:px-60 xl:-mt-0 xl:pt-40 2xl:px-96 2xl:-mt-0 2xl:pt-60"
    />
    <ModalFailed
      v-show="showFailedModal"
      @close="closeModal"
      class="-mt-20 pt-80 pb-60 px-4 md:px-8 md:pt-96 lg:pt-72 lg:px-20 xl:px-60 xl:-mt-0 xl:pt-40 2xl:px-96 2xl:-mt-0 2xl:pt-52"
    />
    <transition name="modal-fade">
      <div
        v-show="open"
        @click="close"
        class="modal-backdrop h-screen w-screen rounded-md content-center"
        id="modal-picture"
      >
        <div
          @click.stop=""
          class="modal align-middle mb-20 my-20 mx-4 md:my-52 lg:my-20 xl:mx-40 xl:my-10 2xl:my-40 2xl:mx-72"
          role="dialog"
          aria-labelledby="modalTitle"
          aria-describedby="modalDescription"
        >
          <header class="modal-header relative indexxx" id="modalTitle">
            <svg
              @click="close"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="currentColor"
              class="bi bi-x text-black ml-auto -mr-3 cursor-pointer"
              viewBox="0 0 16 16"
            >
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </header>

          <section class="modal-body text-center" id="modalDescription">
            <slot name="body">
              <h1
                class="-mt-10 text-3xl md:text-4xl md:-mt-4 xl:-mt-8 xl:text-4xl tracking-widest 2xl:text-6xl 2xl:mt-0"
              >
                {{ dataTitle }}
              </h1>
              <p
                class="px-2 md:px-20 xl:px-40 mt-6 text-lg 2xl:px-72 2xl:mt-6 2xl:text-2xl"
              >
                Kami akan segera menghubungi anda untuk menginformasikan lebih
                lanjut seputar layanan <i>{{ dataTitle }}</i>
              </p>



              <form
                name="formContact"
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                @submit.prevent="sendContactMail"
                class="w-full my-10 px-8 md:px-16 md:mt-10 lg:px-36 xl:px-40 2xl:px-60 2xl:mt-12 relative"
              >
                            <div class="m-auto z-50">
                <router-link to="/kalkulasi" class="bg-brownA text-white py-2 px-10 my-2">KALKULASI ASURANSI</router-link>
              </div>
                <!-- hidden -->
                <input type="hidden" name="form-name" value="formContact" />

                <!-- form name, email, message -->
                <input type="hidden" name="form-name" value="contact" />
                <p class="hidden">
                  <label>Don’t fill this out: <input name="bot-field" /></label>
                </p>
                <div class="bg-whiteBase bg-opacity-50 pt-3 xl:mt-0 xl:gap-6">
                  <div class="items-center border-b border-black py-2 xl:mt-0">
                    <input
                      class="appearance-none text-sm bg-white border-none w-full text-black mr-3 py-1 px-2 leading-tight focus:outline-none 2xl:text-xl"
                      type="text"
                      placeholder="Nama lengkap anda*"
                      aria-label="Full name"
                      name="name"
                      id="name"
                      v-model="formContact.name"
                      required
                    />
                  </div>
                  <div
                    class="items-center border-b border-black py-2 my-4 xl:mb-0 xl:mt-4"
                  >
                    <input
                      class="appearance-none text-sm bg-white border-none w-full text-black mr-3 py-1 px-2 leading-tight focus:outline-none 2xl:text-xl"
                      type="number"
                      placeholder="Nomor Telepon*"
                      aria-label="Nomor Telepon"
                      name="nomor"
                      id="nomor"
                      v-model="formContact.phone"
                      required
                    />
                  </div>
                </div>
                <div
                  class="items-center border-b border-black py-2 my-4 xl:mb-0 xl:mt-4"
                >
                  <input
                    class="appearance-none text-sm bg-white border-none w-full text-black mr-3 py-1 px-2 leading-tight focus:outline-none 2xl:text-xl"
                    type="email"
                    placeholder="Email Anda*"
                    aria-label="Email Anda"
                    name="email"
                    id="email"
                    v-model="formContact.email"
                    required
                  />
                </div>
                <div
                  class="mx-auto w-full mt-4 md:mx-auto md:mt-8 md:w-1/3 xl:mx-auto xl:mt-6 2xl:mx-auto"
                >
                  <button
                    :disabled="isCheck"
                    type="submit"
                    class="form-button mt-3 tracking-wide focus:outline-none font-semibold py-2 text-whiteBase bg-gradient-to-r from-brownCLight to-brownA text-base px-3 border-none bg-darkBrown w-6/12 md:w-full hover:bg-whiteBase hover:text-darkBrown xl:w-full -mb-4 2xl:text-xl"
                  >
                    <svg
                      v-if="isLoading"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="animate-spin bi bi-app-indicator"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"
                      />
                      <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    </svg>
                    KIRIM
                  </button>
                </div>
              </form>
              <img
                src="../assets/background/bg-tentang.webp"
                alt=""
                class="-mt-48 mx-2 md:-mt-80 lg:mt-mtBerandaButtonBottomDekstop xl:mt-mtBerandaLg 2xl:mt-mtWhyTab 2xl:pt-8"
              />
              <p
                class="text-center mt-6 md:-mt-10 lg:-mt-16 xl:text-left ml-5 text-sm xl:-mt-2 2xl:-mt-2 2xl:text-xl"
              >
                ©2020 troyaindoperkasa | Layanan
              </p>
            </slot>
          </section>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import ModalSuccess from "../components/ModalSuccess.vue";
import ModalFailed from "../components/ModalFailed.vue";

export default {
  name: "ModalLayanan",
  props: {
    dataTitle: String,
  },
  components: {
    ModalFailed,
    ModalSuccess,
  },
  methods: {
    close() {
      this.$emit("close");
    },
    changeImage() {
      this.$emit("changeImage");
    },
    closeModal() {
      this.showSuccessModal = false;
    },
    sendContactMail() {
      this.isLoading = true;

      this.formContact.jenisLayanan = this.dataTitle;
      const url = "https://troyaindo.co.id/.netlify/functions/layanan";

      if (!this.validEmail(this.formContact.email)) {
        return (this.notice = "The email address is badly formatted.");
      }

      this.isShow = true;
      this.notice = "";
      axios
        .post(url, this.formContact)
        .then(() => {
          this.open = false;
          this.showSuccessModal = true;
          this.isLoading = false;
        })
        .catch((error) => {
          this.open = false;
          console.log(error);
          this.showFailedModal = true;
          this.isLoading = false;
        })
        .finally(() => {
          this.open = false;
          this.isShow = false;
          this.formContact = {};
        });
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
  data() {
    return {
      visible: false,
      isCheck: false,
      showSuccessModal: false,
      showFailedModal: false,
      open: true,
      isLoading: false,
      formContact: {
        name: "",
        email: "",
        phone: "",
        jenisLayanan: "",
      },
    };
  },
};
</script>

<style>
.indexxx {
  z-index: 99999999999;
}
.modal-backdrop {
  position: fixed;
  background-color: rgb(15, 15, 15, 0.5);
  display: flex;
  justify-content: center;
  z-index: 999;
}

.modal {
  background: #fefefe;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.modal-header,
.modal-footer {
  padding: 15px 23px;
  display: flex;
}

.modal-header {
  position: relative;
  /* border-bottom: 1px solid #eeeeee; */
  color: #4aae9b;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 0px 8px;
  cursor: pointer;
  font-weight: bold;
  color: black;
  background: transparent;
  outline: none;
}

.btn-green {
  color: black;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.img-modal {
  -o-object-fit: cover;
  object-fit: cover;
  background-size: cover;
}
</style>