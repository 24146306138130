<template>
  <div>
    <ModalLayanan
      v-show="isModalVisible"
      @close="closeModal"
      :dataTitle="title"
      class="-mt-80 pt-40 md:pt-44 lg:pt-44 xl:mt-modalXL"
    />

    <div class="bg-whiteBase xl:shadow-inner xl:pt-24">
      <!-- <div class="absolute align-middle bottom-0 right-0">
        <a href="https://wa.me/6281299556161" target="_blank">
          <img
            src="../assets/wa.svg"
            alt=""
            class="w-2/12 h-aut0 fixed z-40 right-0 object-right p-3 -mt-32 -mr-1 md:p-8 md:-mr-4 md:-mt-48 lg:p-14 lg:-mr-9 lg:-mt-52 xl:p-iconWa xl:-mt-56 xl:-mr-16 2xl:-mt-72 2xl:p-32 2xl:-mr-28"
        /></a>
        <svg
          v-show="visible"
          id="backToTopID"
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          fill="currentColor"
          class="bi bi-arrow-up-circle-fill text-blackBlue fixed z-50 right-0 object-right -mt-16 mr-1 px-2 md:px-0 md:mr-6 md:-mt-20 xl:-mt-16 xl:p-1 2xl:-mt-24 2xl:mr-4 cursor-pointer"
          viewBox="0 0 16 16"
          @click="scrollToTop"
        >
          <path
            d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
          />
        </svg>
      </div> -->
      <!-- Start layanan-Header -->
      <div>
        <img
          src="../assets/background/bg-top.webp"
          alt=""
          class="img1 w-10/12 ml-bgMobile pl-20 backdrop-opacity-80 md:w-11/12 md:ml-bgTab md:pl-40 md:-mt-8 lg:ml-bgLg xl:hidden"
        />
        <h1
          class="text-center text-2xl -mt-40 mx-6 md:-mt-80 md:text-4xl lg:mt-mtBerandaButtonBottomDekstop xl:mt-0 xl:text-5xl"
        >
          Dapatkan Semua Layanan yang <br />
          Anda Butuhkan untuk Asuransi Anda.
        </h1>
        <hr
          class="beranda border mt-2 absolute inset-x-24 md:w-1/4 md:mx-auto xl:w-2/12"
        />
        <p class="text-center text-sm mt-8 mx-8 md:mt-14 md:text-lg">
          Layanan kami memberikan jaminan untuk semua kebutuhan yang ingin anda
          asuransikan.
        </p>
      </div>
      <!-- End layanan-Header -->

      <!-- Start layanan Layanan Asuransi -->
      <div
        class="md:mb-32 xl:mb-40 2xl:mb-80 md:grid md:grid-cols-2 md:mx-2 md:gap-6 md:px-6 md:mt-20 lg:mx-10 lg:px-6 xl:grid-cols-3 2xl:mx-24 2xl:px-4 2xl:gap-8"
      >

        <!-- mobil -->
        <div class="md:mx-0 md:mt-4 shadow-md mx-6 mt-10 bg-whiteBase relative order-2 ">
          <img src="../assets/layanan/layanan-mobil.webp" alt="" />
          <div class="mx-6 my-8 lg:px-10 xl:px-6">
            <div class="flex">
              <div class="flex-1 mr-10 xs:mr-5 ">
                <img
                  src="../assets/layanan/asuransi-mobil.svg"
                  alt=""
                  class="w-2/12 -ml-1 2xl:ml-8"
                />
              </div>
              <div class="flex-1 -ml-52 pl-2">
                <h5 class="text-2xl font-semibold mt-1">Asuransi Mobil</h5>
                <p class="text-sm mt-2 mr-4 md:text-base md:leading-tight">
                  Menjamin pembayaran ganti rugi atas kerusakan Mobil
                </p>
              </div>
            </div>
            <div class="flex mt-3">
              <div class="flex-1 ml-1  2xl:ml-16 mr-10 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-info-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                  />
                </svg>
              </div>
              <div class="flex-1 ml-2 2xl:-ml-8">
                <h5
                  class="-ml-36 mr-4 pl-7 leading-tight md:text-base md:leading-tight"
                >
                  Jika anda ingin info lebih lanjut seputar layanan
                  <i class="font-semibold">Asuransi Mobil,</i> Silahkan lihat
                  layanan:
                </h5>
              </div>
            </div>
            <div class="mx-12 mt-4">
              <router-link to="#">
                <button
                  type="button"
                  @click="showModal('Asuransi Mobil')"
                  class="mt-4 tracking-widest focus:outline-none font-semibold py-1.5 text-whiteBase bg-gradient-to-r from-brownCLight to-brownA text-base px-3 border-none bg-darkBrown hover:bg-whiteBase hover:text-darkBrown w-full mb-8 md:mb-2"
                >
                  HUBUNGI KAMI
                </button></router-link
              >
            </div>
          </div>
        </div>

        <!-- motor -->
        <div class="md:mx-0 md:mt-4 shadow-md mx-6 mt-10 bg-whiteBase relative order-3">
          <img src="../assets/layanan/layanan-motor.webp" alt="" />
          <div class="mx-6 my-8 lg:px-10 xl:px-6">
            <div class="flex">
              <div class="flex-1 2xl:ml-8 mr-10 xs:mr-5">
                <img
                  src="../assets/layanan/asuransi-motor.svg"
                  alt=""
                  class="w-2/12 -ml-1"
                />
              </div>
              <div class="flex-1 -ml-52 pl-2 2xl:-ml-60">
                <h5 class="text-2xl font-semibold mt-1">Asuransi Motor</h5>
                <p class="text-sm mt-2 mr-4 md:text-base md:leading-tight">
                  Menjamin pembayaran ganti rugi atas kerugian motor anda.
                </p>
              </div>
            </div>
            <div class="flex mt-3">
              <div class="flex-1 ml-1 2xl:ml-16 mr-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-info-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                  />
                </svg>
              </div>
              <div class="flex-1 ml-2">
                <h5
                  class="-ml-36 pl-7 mr-4 leading-tight md:text-base md:leading-tight 2xl:-ml-40 2xl:pl-5"
                >
                  Jika anda ingin info lebih lanjut seputar layanan
                  <i class="font-semibold">Asuransi Motor,</i> Silahkan lihat
                  layanan:
                </h5>
              </div>
            </div>
            <div class="mx-12 mt-4">
              <router-link to="#">
                <button
                  type="button"
                  @click="showModal('Asuransi Properti')"
                  class="mt-4 tracking-widest focus:outline-none font-semibold py-1.5 text-whiteBase bg-gradient-to-r from-brownCLight to-brownA text-base px-3 border-none bg-darkBrown hover:bg-whiteBase hover:text-darkBrown w-full mb-8 md:mb-2"
                >
                  HUBUNGI KAMI
                </button></router-link
              >
            </div>
          </div>
        </div>

        <!-- property -->
        <div class="md:mx-0 md:mt-4 shadow-md mx-6 mt-10 bg-whiteBase relative order-1 ">
          <img src="../assets/layanan/layanan-properti.webp" alt="" />
          <div class="mx-6 my-8 lg:px-10 xl:px-6">
            <div class="flex">
              <div class="flex-1 2xl:ml-8 mr-10 xs:mr-5">
                <img
                  src="../assets/layanan/asuransi-properti.svg"
                  alt=""
                  class="w-2/12 -ml-1"
                />
              </div>
              <div class="flex-1 -ml-52 pl-2 2xl:-ml-60">
                <h5 class="text-2xl font-semibold mt-1">Asuransi Properti</h5>
                <p class="text-sm mr-4 mt-2 md:text-base md:leading-tight">
                  Menjamin pembayaran ganti rugi atas kerugian properti anda.
                </p>
              </div>
            </div>
            <div class="flex mt-3">
              <div class="flex-1 ml-1 mr-10 2xl:ml-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-info-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                  />
                </svg>
              </div>
              <div class="flex-1 ml-2">
                <h5
                  class="-ml-36 pl-7 mr-4 leading-tight md:text-base md:leading-tight 2xl:-ml-40 2xl:pl-5"
                >
                  Jika anda ingin info lebih lanjut seputar layanan
                  <i class="font-semibold">Asuransi Properti,</i> Silahkan
                  Hubungi:
                </h5>
              </div>
            </div>
            <div class="mx-12 mt-4">
              <button
                @click="showModal('Asuransi Motor')"
                type="button"
                class="mt-4 tracking-widest focus:outline-none font-semibold py-1.5 text-whiteBase bg-gradient-to-r from-brownCLight to-brownA text-base px-3 border-none bg-darkBrown hover:bg-whiteBase hover:text-darkBrown w-full mb-8 md:mb-2"
              >
                HUBUNGI KAMI
              </button>
            </div>
          </div>
        </div>

        <!-- kesehatan -->
        <div class="md:mx-0 md:mt-4 shadow-md mx-6 mt-10 bg-whiteBase relative order-6">
          <img src="../assets/layanan/layanan-kesehatan.webp" alt="" />
          <div class="mx-6 my-8 lg:px-10 xl:px-6">
            <div class="flex">
              <div class="flex-1 mr-10 xs:mr-5 2xl:ml-8">
                <img
                  src="../assets/layanan/asuransi-kesehatan.svg"
                  alt=""
                  class="w-2/12 -ml-1"
                />
              </div>
              <div class="flex-1 -ml-52 pl-2 2xl:-ml-60">
                <h5 class="text-2xl font-semibold mt-1">Asuransi Kesehatan</h5>
                <p class="text-sm mt-2 mr-4 md:text-base md:leading-tight">
                  Menjamin pembayaran ganti rugi atas kesehatan anda.
                </p>
              </div>
            </div>
            <div class="flex mt-3">
              <div class="flex-1 ml-1 mr-10 2xl:ml-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-info-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                  />
                </svg>
              </div>
              <div class="flex-1 ml-2 2xl:-ml-8">
                <h5
                  class="-ml-36 pl-7 mr-4 leading-tight md:text-base md:leading-tight"
                >
                  Jika anda ingin info lebih lanjut seputar layanan
                  <i class="font-semibold">Asuransi Kesehatan,</i> Silahkan
                  Hubungi:
                </h5>
              </div>
            </div>
            <div class="mx-12 mt-4">
              <button
                @click="showModal('Asuransi Kesehatan')"
                type="button"
                class="mt-4 tracking-widest focus:outline-none font-semibold py-1.5 text-whiteBase bg-gradient-to-r from-brownCLight to-brownA text-base px-3 border-none bg-darkBrown hover:bg-whiteBase hover:text-darkBrown w-full mb-8 md:mb-2"
              >
                HUBUNGI KAMI
              </button>
            </div>
          </div>
        </div>

        <!-- barang -->
        <div class="md:mx-0 md:mt-4 shadow-md mx-6 mt-10 bg-whiteBase relative order-5 ">
          <img src="../assets/layanan/layanan-barang.webp" alt="" />
          <div class="mx-6 my-8 lg:px-10 xl:px-6">
            <div class="flex">
              <div class="flex-1 2xl:ml-8 mr-10 xs:mr-5">
                <img
                  src="../assets/layanan/asuransi-barang.svg"
                  alt=""
                  class="w-2/12 -ml-1"
                />
              </div>
              <div class="flex-1 -ml-52 pl-2 2xl:-ml-60">
                <h5 class="text-xl font-semibold mt-1">
                  Asuransi Pengangkutan Barang
                </h5>
                <p class="text-sm mt-2 mr-4 md:text-base md:leading-tight">
                  Menjamin reriko pengangkutan barang yang diasuransikan
                </p>
              </div>
            </div>
            <div class="flex mt-3">
              <div class="flex-1 ml-1 mr-10 2xl:ml-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-info-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                  />
                </svg>
              </div>
              <div class="flex-1 ml-2 2xl:-ml-8">
                <h5
                  class="-ml-36 pl-7 mr-4 leading-tight md:text-base md:leading-tight md:-mr-3"
                >
                  Jika anda ingin info lebih lanjut seputar layanan
                  <i class="font-semibold">Asuransi Pengangkutan Barang,</i>
                  Silahkan Hubungi:
                </h5>
              </div>
            </div>
            <div class="mx-12 mt-4">
              <button
                @click="showModal('Asuransi Pengangkutan Barang')"
                type="button"
                class="mt-4 tracking-widest focus:outline-none font-bold py-1.5 text-whiteBase bg-gradient-to-r from-brownCLight to-brownA text-base px-3 border-none bg-darkBrown hover:bg-whiteBase hover:text-darkBrown w-full mb-8 md:mb-2"
              >
                HUBUNGI KAMI
              </button>
            </div>
          </div>
        </div>

        <!-- elektronik -->
        <!-- <div class="md:mx-0 md:mt-4 shadow-md mx-6 mt-10 bg-whiteBase relative order-8 ">
          <img src="../assets/layanan/layanan-elektronik.webp" alt="" />
          <div class="mx-6 my-8 lg:px-10 xl:px-6">
            <div class="flex">
              <div class="flex-1 mr-10 xs:mr-5 2xl:ml-8">
                <img
                  src="../assets/layanan/asuransi-elektronik.svg"
                  alt=""
                  class="w-2/12 -ml-1"
                />
              </div>
              <div class="flex-1 -ml-52 pl-2 2xl:-ml-60">
                <h5 class="text-xl font-semibold mt-1">
                  Asuransi Perangkat Elektronik
                </h5>
                <p class="text-sm mt-2 mr-4 md:text-base md:leading-tight">
                  Menjamin pembayaran ganti rugi atas kerusakan suatu perangkat.
                </p>
              </div>
            </div>
            <div class="flex mt-3">
              <div class="flex-1 ml-1 mr-10 2xl:ml-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-info-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                  />
                </svg>
              </div>
              <div class="flex-1 ml-2">
                <h5
                  class="-ml-36 pl-7 mr-4 leading-tight md:text-base md:leading-tight 2xl:-ml-40 2xl:pl-5"
                >
                  Jika anda ingin info lebih lanjut seputar layanan
                  <i class="font-semibold">Asuransi Perangkat Elektronik,</i>
                  Silahkan Hubungi:
                </h5>
              </div>
            </div>
            <div class="mx-12 mt-4">
              <button
                @click="showModal('Asuransi Perangkat Elektronik')"
                type="button"
                class="mt-4 tracking-widest focus:outline-none font-bold py-1.5 text-whiteBase bg-gradient-to-r from-brownCLight to-brownA text-base px-3 border-none bg-darkBrown hover:bg-whiteBase hover:text-darkBrown w-full mb-8 md:mb-2"
              >
                HUBUNGI KAMI
              </button>
            </div>
          </div>
        </div> -->

        <!-- Surety Bond -->
        <div class="md:mx-0 md:mt-4 shadow-md mx-6 mt-10 bg-whiteBase relative order-7">
          <img src="../assets/layanan/layanan-surety.webp" alt="" />
          <div class="mx-6 my-8 lg:px-10 xl:px-6">
            <div class="flex">
              <div class="flex-1 mr-10 xs:mr-5 2xl:ml-8">
                <img
                  src="../assets/layanan/asuransi-surety.svg"
                  alt=""
                  class="w-2/12 -ml-1"
                />
              </div>
              <div class="flex-1 -ml-52 pl-2 2xl:-ml-60">
                <h5 class="text-xl font-semibold mt-1">Asuransi Surety Bond</h5>
                <p class="text-sm mt-2 mr-4 md:text-base md:leading-tight">
                  Menjamin terjadinya wanprestasi antara Pemilik Pekerjaan
                  dengan Pelaksana Pekerjaan, yang sering timbul pada pekerjaan
                  baik konstruksi maupun non kontruksi.
                </p>
              </div>
            </div>
            <div class="flex mt-3">
              <div class="flex-1 ml-1 mr-10 2xl:ml-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-info-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                  />
                </svg>
              </div>
              <div class="flex-1 ml-2">
                <h5
                  class="-ml-36 pl-7 mr-4 leading-tight md:text-base md:leading-tight 2xl:-ml-40 2xl:pl-5"
                >
                  Jika anda ingin info lebih lanjut seputar layanan
                  <i class="font-semibold">Asuransi Surety Bond,</i>
                  Silahkan Hubungi:
                </h5>
              </div>
            </div>
            <div class="mx-12 mt-4">
              <button
                @click="showModal('Asuransi Surety Bond')"
                type="button"
                class="mt-4 tracking-widest focus:outline-none font-bold py-1.5 text-whiteBase bg-gradient-to-r from-brownCLight to-brownA text-base px-3 border-none bg-darkBrown hover:bg-whiteBase hover:text-darkBrown w-full mb-8 md:mb-2"
              >
                HUBUNGI KAMI
              </button>
            </div>
          </div>
        </div>


        <!-- alat berat / heavy equipment -->
        <div class="md:mx-0 md:mt-4 shadow-md mx-6 mt-10 bg-whiteBase relative order-4">
          <img src="../assets/layanan/layanan-alatberat.webp" alt="" />
          <div class="mx-6 my-8 lg:px-10 xl:px-6">
            <div class="flex">
              <div class="flex-1 mr-10 xs:mr-5 2xl:ml-8">
                <img
                  src="../assets/layanan/asuransi-alatberat.svg"
                  alt=""
                  class="w-2/12 -ml-1"
                />
              </div>
              <div class="flex-1 -ml-52 pl-2 2xl:-ml-60">
                <h5 class="text-xl font-semibold mt-1">Asuransi Heavy Equipment</h5>
                <p class="text-sm mt-2 mr-4 md:text-base md:leading-tight">
                  Menjamin terjadinya kerusakan yang biasa terjadi dalam sebuah
                  proyek terhadap alat-alat berat baik pada saat digunakan,
                  maupun sedang dibongkar.
                </p>
              </div>
            </div>
            <div class="flex mt-3">
              <div class="flex-1 ml-1 mr-10 2xl:ml-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-info-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                  />
                </svg>
              </div>
              <div class="flex-1 ml-2">
                <h5
                  class="-ml-36 pl-7 mr-4 leading-tight md:text-base md:leading-tight 2xl:-ml-40 2xl:pl-5"
                >
                  Jika anda ingin info lebih lanjut seputar layanan
                  <i class="font-semibold">Asuransi Heavy Equipment,</i>
                  Silahkan Hubungi:
                </h5>
              </div>
            </div>
            <div class="mx-12 mt-4">
              <button
                @click="showModal('Asuransi Alat Berat')"
                type="button"
                class="mt-4 tracking-widest focus:outline-none font-bold py-1.5 text-whiteBase bg-gradient-to-r from-brownCLight to-brownA text-base px-3 border-none bg-darkBrown hover:bg-whiteBase hover:text-darkBrown w-full mb-8 md:mb-2"
              >
                HUBUNGI KAMI
              </button>
            </div>
          </div>
        </div>


<!-- 
        <div class="md:col-span-2 md:-mx-8 lg:-mx-16 xl:col-span-3 2xl:-mx-28">
          <img
            src="../assets/background/bg-bottom.webp"
            alt=""
            class="-mt-40 md:-mt-80 lg:-mt-96 xl:mt-mlButtonBeranda 2xl:mt-mtBerandaLayananBottomDekstop"
          />
        </div> -->
      </div>
      <!-- End layanan Layanan Asuransi -->



      <!-- Start layanan Klaim Asuransi -->
      <div class="mx-6 mt-10 md:mx-10 md:mt-0 lg:mx-16 2xl:mx-28 2xl:-mt-52">
        <h5 class="tracking-widest text-lg md:text-xl xl:text-2xl">
          KLAIM ASURANSI
        </h5>
        <h1 class="text-2xl mr-14 mt-2 md:text-3xl xl:text-5xl uppercase">
          Tata cara lapor klaim asuransi
        </h1>
        <hr class="klaim w-4/12 mt-3 md:w-1/5 2xl:relative 2xl:w-1/6" />
        <p
          class="mt-4 text-sm mr-8 mb-2 md:mt-8 md:text-lg mx-auto md:mr-8 xl:mr-mlBerandaBottomRightLG 2xl:mr-mrBerandaLayananTitle2xl 2xl:pr-20"
        >
          Kami memberikan layanan terbaik untuk anda terutama untuk proses klaim
          asuransi anda dapat melakukan klaim asuransi secara mudah dan cepat.
        </p>
        <div
          class="grid grid-cols-1 mb-10 md:grid-cols-2 md:gap-12 md:mt-10 xl:grid-cols-8"
        >
          <div class="mt-8 md:mt-0 shadow-md xl:col-span-2 xl:-mr-10">
            <div class="bg-gradient-to-r from-brownCLight to-brownA py-1">
              <h5
                class="text-center tracking-widest text-whiteBase text-lg pt-2 pb-2 2xl:text-xl uppercase"
              >
                Langkah 01
              </h5>
            </div>
            <div class="bg-whiteBase py-7">
              <div class="flex">
                <div class="flex-initial flex-shrink-0">
                  <img
                    src="../assets/layanan/info.svg"
                    alt=""
                    class="ml-6"
                  />
                </div>
                <div class="flex-initial">
                  <p
                    class="mx-6 pr-10 text-sm 2xl:text-lg 2xl:leading-tight"
                  >
                    Tertanggung lapor klaim ke PT Troya Indo Perkasa
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="hidden xl:block my-auto mx-auto">
            <img src="../assets/arrow-right.svg" alt="" />
          </div>

          <div class="mt-8 md:mt-0 shadow-md xl:col-span-2 xl:-mx-7">
            <div class="bg-gradient-to-r from-brownCLight to-brownA py-1">
              <h5
                class="text-center tracking-widest text-whiteBase text-lg pt-2 pb-2 2xl:text-xl uppercase"
              >
                Langkah 02
              </h5>
            </div>
            <div class="bg-whiteBase py-7">
              <div class="flex">
                <div class="flex-initial flex-shrink-0">
                  <img
                    src="../assets/layanan/info.svg"
                    alt=""
                    class="ml-6"
                  />
                </div>
                <div class="flex-initial">
                  <p
                    class="mx-6 text-sm pr-10 2xl:text-lg 2xl:leading-tight"
                  >
                    PT Troya Indo Perkasa lapor ke pihak Klaim Asuransi
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="hidden xl:block my-auto mx-auto">
            <img src="../assets/arrow-right.svg" alt="" />
          </div>

          <div class="mt-8 md:mt-0 shadow-md xl:col-span-2 xl:-ml-10">
            <div class="bg-gradient-to-r from-brownCLight to-brownA py-1">
              <h5
                class="text-center tracking-widest text-whiteBase text-lg  pt-2 pb-2 2xl:text-xl uppercase"
              >
                Langkah 03
              </h5>
            </div>
            <div class="bg-whiteBase py-7">
              <div class="flex">
                <div class="flex-initial flex-shrink-0">
                  <img
                    src="../assets/layanan/info.svg"
                    alt=""
                    class="ml-6"
                  />
                </div>
                <div class="flex-initial">
                  <p
                    class="mx-6 text-sm pr-10 2xl:text-lg 2xl:leading-tight"
                  >
                    Bagian Klaim akan menghubungi Tertanggung untuk menentukan jadwal surveyor
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- end row -->

          <div class="hidden xl:block my-auto mx-auto col-span-2">
            <img src="../assets/arrow-top.svg" alt="" />
          </div>

          <div class="hidden xl:block my-auto mx-auto col-span-4"></div>
          <div class="hidden xl:block my-auto mx-auto col-span-2">
            <img src="../assets/arrow-bottom.svg" alt="" />
          </div>

          <!-- mobile 04 -->
          <div class="mt-8 md:mt-0 shadow-md xl:col-span-2 xl:-mx-7 xl:hidden">
            <div class="bg-gradient-to-r from-brownCLight to-brownA py-1">
              <h5
                class="text-center tracking-widest text-whiteBase text-lg  pt-2 pb-2 2xl:text-xl uppercase"
              >
                Langkah 04
              </h5>
            </div>
            <div class="bg-whiteBase py-7">
              <div class="flex">
                <div class="flex-initial flex-shrink-0">
                  <img
                    src="../assets/layanan/info.svg"
                    alt=""
                    class="ml-6"
                  />
                </div>
                <div class="flex-initial">
                  <p class="mx-6 text-sm  2xl:text-lg 2xl:leading-tight">
                    Tertanggung melengkapi dokumen pendukung dan laporan - laporan Klaim
                  </p>
                </div>
              </div>
            </div>
          </div>


          <!-- Dekstop last 06 -->
          <div
            class="hidden xl:block mt-8 md:mt-0 shadow-md xl:col-span-2 xl:-mr-10"
          >
            <div class="bg-gradient-to-r from-brownCLight to-brownA py-1">
              <h5
                class="text-center tracking-widest text-whiteBase text-lg ml-6 pt-2 pb-2 2xl:text-xl uppercase"
              >
                Langkah 06
              </h5>
            </div>
            <div class="bg-whiteBase py-7">
              <div class="flex">
                <div class="flex-initial flex-shrink-0">
                  <img
                    src="../assets/layanan/info.svg"
                    alt=""
                    class="ml-6"
                  />
                </div>
                <div class="flex-initial">
                  <p
                    class="mx-6 text-sm pr-10 2xl:text-lg 2xl:leading-tight"
                  >
                      Cutomer Care akan follow up ke pihak Terganggung dan Bengkel untuk memastikan penyelesaian klaim
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="hidden xl:block my-auto mx-auto">
            <img src="../assets/arrow-left.svg" alt="" />
          </div>



          <!-- mobile 5 -->
          <div class="mt-8 md:mt-0 shadow-md xl:col-span-2 xl:-mr-10 xl:hidden">
            <div class="bg-gradient-to-r from-brownCLight to-brownA py-1">
              <h5
                class="text-center tracking-widest text-whiteBase text-lg pt-2 pb-2 2xl:text-xl uppercase"
              >
                Langkah 05
              </h5>
            </div>
            <div class="bg-whiteBase py-7">
              <div class="flex">
                <div class="flex-initial flex-shrink-0">
                  <img
                    src="../assets/layanan/info.svg"
                    alt=""
                    class="ml-6"
                  />
                </div>
                <div class="flex-initial">
                  <p
                    class="mx-7 text-sm pr-10 2xl:text-lg 2xl:leading-tight"
                  >
                    Surveyor akan menentukan bengkel rekanan kemudian menerbitkan Surat Perintah Kerja  & Surat Perintah Order ( SPK & SPO )
                  </p>
                </div>
              </div>
            </div>
          </div>


          <!-- mobile 06 -->
          <div class="mt-8 md:mt-0 shadow-md xl:col-span-2 xl:-mx-7 xl:hidden">
            <div class="bg-gradient-to-r from-brownCLight to-brownA py-1">
              <h5
                class="text-center tracking-widest text-whiteBase text-lg  pt-2 pb-2 2xl:text-xl uppercase"
              >
                Langkah 06
              </h5>
            </div>
            <div class="bg-whiteBase py-7">
              <div class="flex">
                <div class="flex-initial flex-shrink-0">
                  <img
                    src="../assets/layanan/info.svg"
                    alt=""
                    class="ml-6"
                  />
                </div>
                <div class="flex-initial">
                  <p class="mx-6 text-sm  2xl:text-lg 2xl:leading-tight">
                    Cutomer Care akan follow up ke pihak Terganggung dan Bengkel untuk memastikan penyelesaian klaim
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- desktop 05 -->
          <div class="mt-8 md:mt-0 shadow-md xl:col-span-2 xl:-mx-7 xl:block hidden">
            <div class="bg-gradient-to-r from-brownCLight to-brownA py-1">
              <h5
                class="text-center tracking-widest text-whiteBase text-lg  pt-2 pb-2 2xl:text-xl uppercase"
              >
                Langkah 05
              </h5>
            </div>
            <div class="bg-whiteBase py-7">
              <div class="flex">
                <div class="flex-initial flex-shrink-0">
                  <img
                    src="../assets/layanan/info.svg"
                    alt=""
                    class="ml-6"
                  />
                </div>
                <div class="flex-initial">
                  <p class="mx-6 text-sm  2xl:text-lg 2xl:leading-tight">
                    Surveyor akan menentukan bengkel rekanan kemudian menerbitkan Surat Perintah Kerja  & Surat Perintah Order ( SPK & SPO )
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class=" opacity-0">
            
          </div>
          <div class="hidden xl:block mt-8 md:mt-0  xl:col-span-2 xl:-ml-10 relative">
            <img src="@/assets/layanan/arrow-new.svg" alt="" class=" absolute -top-16 -left-16">
          </div> -->
          
          <div class="hidden xl:block my-auto mx-auto">
            <img src="../assets/arrow-left.svg" alt="" />
          </div>

          <div
            class="hidden xl:block mt-8 md:mt-0 shadow-md xl:col-span-2 xl:-ml-10"
          >
            <div class="bg-gradient-to-r from-brownCLight to-brownA py-1">
              <h5
                class="text-center tracking-widest text-whiteBase text-lg pt-2 pb-2 2xl:text-xl uppercase"
              >
                Langkah 04
              </h5>
            </div>
            <div class="bg-whiteBase py-7">
              <div class="flex">
                <div class="flex-initial flex-shrink-0">
                  <img
                    src="../assets/layanan/info.svg"
                    alt=""
                    class="ml-6"
                  />
                </div>
                <div class="flex-initial">
                  <p class="mx-7 text-sm  2xl:text-lg 2xl:leading-tight">
                    Tertanggung melengkapi dokumen pendukung dan laporan - laporan Klaim 
                  </p>
                </div>
              </div>
            </div>
          </div>
        
        
        </div>
      </div>
      <!-- End layanan Klaim Asuransi -->






      <!-- Start Benefit -->
      <div
        class="mx-6 mt-28 mb-16 md:mt-32 md:mx-10 lg:mx-16 2xl:mt-44 2xl:mx-28"
      >
        <h5
          id="benefit"
          class="tracking-widest text-base md:text-xl xl:text-2xl"
        >
          BENEFITS
        </h5>
        <h1 class="text-2xl mr-2 mt-2 md:text-3xl xl:text-5xl">
          Mengapa Memilih Troya Indo Perkasa ?
        </h1>
        <hr class="klaim mt-3 w-5/12" />
        <p
          class="mt-4 text-sm mb-2 mr-20 md:mt-8 md:text-lg md:mr-8 xl:mt-10 xl:mr-mtBerandaKarirButtonDekstop 2xl:mr-mtBerandaLayananDekstop 2xl:pr-32"
        >
          Kami memberikan layanan terbaik untuk anda dan layanan asuransi kami
          akan menjamin semua kebutuhan yang anda butuhkan.
        </p>
        <div
          class="bg-lightCream bg-opacity-50 -mx-6 mt-10 p-1 mb-10 md:-mx-10 md:mt-10 md:mb-14 lg:-mx-16 xl:mt-14 2xl:-mx-28 2xl:px-28"
        >
          <img
            src="../assets/layanan/bg-benefit.webp"
            alt=""
            class="md:bg-contain md:ml-auto md:mt-20 md:opacity-50 lg:object-cover lg:bg-cover lg:w-8/12 xl:w-4/12 xl:opacity-90 xl:mt-10 2xl:w-5/12"
          />
          <div
            class="md:mt-mtWhyTab md:grid md:grid-cols-2 md:ml-10 md:gap-6 md:mr-48 md:mb-14 lg:mr-60 lg:mt-mtBerandaLayananBottomDekstop xl:grid-cols-4 xl:-mt-96 xl:mx-16 2xl:mx-0 2xl:mt-mtBerandaBottomLG 2xl:mr-32"
          >
            <div
              class="relative bg-whiteBase -mt-80 pt-8 mx-10 mb-6 text-center md:-mt-0 md:mx-0 md:mb-0 2xl:py-20"
            >
              <img
                src="../assets/layanan/why-fasilitas.svg"
                alt=""
                class="mx-auto"
              />
              <h4 class="text-xl mt-4 font-semibold 2xl:text-2xl 2xl:mt-8">
                Fasilitas Asuransi
              </h4>
              <p class="text-sm mt-4 px-6 pb-8 xl:px-10 2xl:text-lg 2xl:px-14">
                PT. Troya Indo Perkasa memiliki fasilitas asuransi yang mampu
                membantu memenuhi kebutuhan anda seperti, asuransi kendaraan,
                asuransi kesehatan, asuransi elektronik, dll.
              </p>
            </div>
            <div
              class="relative bg-whiteBase pt-8 mx-10 mb-6 text-center md:mx-0 md:mb-0 2xl:py-20"
            >
              <img
                src="../assets/layanan/why-claim.svg"
                alt=""
                class="mx-auto"
              />
              <h4 class="text-xl mt-4 font-semibold 2xl:text-2xl 2xl:mt-8">
                Klaim Asuransi Mudah
              </h4>
              <p class="text-sm mt-4 px-6 pb-8 2xl:text-lg 2xl:px-14 xl:px-10">
                PT. Troya Indo Perkasa membantu anda untuk mengklaim layanan
                asuransi secara mudah dan cepat.
              </p>
            </div>
            <div
              class="relative bg-whiteBase pt-8 mx-10 mb-6 pb-6 text-center md:mx-0 md:mb-0 2xl:py-20"
            >
              <img src="../assets/layanan/why-ojk.svg" alt="" class="mx-auto" />
              <h4 class="text-xl mt-4 font-semibold 2xl:text-2xl 2xl:mt-8">
                Terdaftar Otoritas Jasa Keuangan
              </h4>
              <p class="text-sm mt-4 px-6 pb-2 2xl:text-lg 2xl:px-14 xl:px-10">
                PT. Troya Indo Perkasa sudah terdaftar pada otoritas jasa
                keuangan pada 19 Agustus 2020.
              </p>
              <p class="text-sm mt-4 px-6 pb-2 2xl:text-lg 2xl:px-14">
                Nomor OJK:
              </p>
              <p
                class="text-sm mx-14 border-dashed border p-1 border-brownA 2xl:text-lg 2xl:px-4"
              >
                S-1259/NB.122/2020
              </p>
            </div>
            <div
              class="relative bg-whiteBase pt-8 mx-10 mb-6 text-center md:mx-0 md:mb-0 2xl:py-20"
            >
              <img
                src="../assets/layanan/why-layanan.svg"
                alt=""
                class="mx-auto"
              />
              <h4 class="text-xl mt-4 font-semibold 2xl:text-2xl 2xl:mt-8">
                Layanan Situs Help Center
              </h4>
              <p
                class="text-sm mt-4 px-6 pb-8 mx-4 2xl:text-lg 2xl:px-14 xl:px-8"
              >
                PT. Troya Indo Perkasa memiliki situs yang dapat membantu anda
                menghubungi customer service kami untuk setiap pertanyaan anda.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- End Benefit -->
    </div>
  </div>
</template>

<script>
import ModalLayanan from "../components/ModalLayanan.vue";

export default {
  name: "Layanan",
  components: {
    ModalLayanan,
  },
  data() {
    return {
      visible: false,
      isModalVisible: false,
      selectedLayanan: String,
      title: String,
    };
  },
  created() {
    window.addEventListener("scroll", this.getIcon);
  },
  destroyed() {
    window.removeEventListener("scroll", this.getIcon);
  },
  methods: {
    getIcon(event) {
      if (event.path[1].pageYOffset < 100) {
        this.visible = false;
      } else {
        this.visible = true;
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    showModal(selectedTitle) {
      this.isModalVisible = true;
      this.title = selectedTitle;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    
  },
};
</script>
